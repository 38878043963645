import { HttpErrorResponse, HttpEvent, HttpEventType, HttpHandler, HttpHandlerFn, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
// import { MessageService } from 'primeng/api';
import { inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MessageService } from 'primeng/api';
import { environment } from '../../../environments/environment';
import { AuthService } from '../models/auth/auth.service';
import { Router } from '@angular/router';

export var isRefreshing: boolean = false;
export const httpVariables = {
  BASE_URL : environment.apiUrl,
  AUTH_TOKEN : 'auth-token',
  HEADER_AUTHORIZATION : 'Authorization',
  HEADER_ACCEPT : 'Accept',
  HEADER_CONTENT_TYPE : 'Content-Type',
  ACCEPT_LANGUAGE : 'Accept-Language',
  LANGUAGE : 'X-LANGUAGE',
  CLIENT : 'X-CLIENT',
}

export function refreshToken(req: HttpRequest<any>, next: HttpHandlerFn, messageService: MessageService, 
  platformId: {}, authService: AuthService) : Observable<HttpEvent<unknown>>{
  isRefreshing = true;
  return authService.refreshTokenStatic(authService.getRefreshToken()).pipe(
    switchMap((token: any) => {
      isRefreshing = false;
     // authService.setToken(token.token.accessToken);
     authService.setToken(token.token);
     // SET NEW REFRESH TOKEN
     authService.setRefreshToken(token.refreshToken)
      const newToken = authService.getToken();
      const transformedReq = req.clone({
        headers: req.headers.set(httpVariables.HEADER_AUTHORIZATION, `Bearer ${newToken}`)
      });
      return next(transformedReq);
    }),
    catchError((error: HttpErrorResponse) => {
      isRefreshing = false;
      authService.logout();
      const router = inject(Router);
      router.navigate(['auth/login']);
      return handleHttpError(messageService,platformId, error); // Return the observable returned by handleHttpError
    })
  )
}


export function mainInterceptor(req: HttpRequest<unknown>,next: HttpHandlerFn
  ): Observable<HttpEvent<unknown>>  {


    const platformId = inject(PLATFORM_ID);
    const messageService = inject(MessageService);
    const authService = inject(AuthService);
    if (req.headers.get('Content-type')) {
      return next(req);
    }
    if (req.headers.get('skip')) {
      return next(req);
    }
    if (req.url.startsWith('./assets') || req.url.startsWith('/assets')) {
      return next(req);
    }
    req = req.clone({
      url: _prefixUrl(req.url)
    });



   req = req.clone({
    headers: req.headers.set('Cache-Control', 'no-cache')
  });


  const tokenExpired = !authService.checkIfTokenExpired();
  if(!req.headers.get('skipRefreshToken')){

    if(tokenExpired && authService.getToken()){
      if(!isRefreshing){

        return refreshToken(req, next, messageService, platformId, authService);
      } else {
        return EMPTY;
      }
    } else {
      if (req.headers.get('token')) {
        const token = authService.getToken();
        if (token) {
          req = req.clone({
            headers: req.headers.set(httpVariables.HEADER_AUTHORIZATION, `Bearer ${token}`)
          });
        }
      }
      return next(req).pipe(
        tap((event) => {
          if (event.type === HttpEventType.Response) {

          }
        }),
        catchError((error: HttpErrorResponse) => {
          console.error(error);
          return handleHttpError(messageService,platformId, error); // Return the observable returned by handleHttpError
        })
      );
    }
  } else {
    if (req.headers.get('token')) {
      const token = authService.getToken();
      if (token) {
        req = req.clone({
          headers: req.headers.set(httpVariables.HEADER_AUTHORIZATION, `Bearer ${token}`)
        });
      }
    }
    return next(req).pipe(
      tap((event) => {
        if (event.type === HttpEventType.Response) {
            console.log('RESPONSE WITH TOKEN')
        }
      }),
      catchError((error: HttpErrorResponse) => {
        return handleHttpError(messageService,platformId, error); // Return the observable returned by handleHttpError
      })
    );
  }

  }


  export function handleHttpError(messageService: MessageService, platformId: {}, httpErrorResponse: HttpErrorResponse): Observable<HttpEvent<unknown>> {
    console.log('HTTP ERROR RESPONSE', httpErrorResponse);
      if(httpErrorResponse.error.status === 0){
        displayErrorNotification(messageService,platformId, httpErrorResponse, 'Could not connect to server!');
      } else {
        displayErrorNotification(messageService,platformId, httpErrorResponse);
      }

      return throwError(httpErrorResponse);
    
  }


    export function displayErrorNotification(messageService: MessageService, platformId: {}, httpErrorResponse?: HttpErrorResponse, customMessage?: string): void {
      
        if(isPlatformBrowser(platformId)){
          if(customMessage){
            messageService.add({ severity: 'error', key:'notifications', summary: 'HTTP ERROR', detail: customMessage });
          } else if(httpErrorResponse){
              messageService.add({ severity: 'error', key:'notifications', summary: httpErrorResponse.name || 'HTTP ERROR', detail: httpErrorResponse.error.message || customMessage });
          }
        }
    }


    export function _prefixUrl(path: string): string {
      if (path.indexOf('/') === 0) {
        path = path.substr(1, path.length - 1);
      }
  
      return `${httpVariables.BASE_URL}/${path}`;
    }