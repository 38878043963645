import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
const config: SocketIoConfig = { url: 'https://api.dev.qualylab.lt:82', options: {
    transportOptions: {polling: {extraHeaders: {Authorization: 'JFcBrjh9JYgHACECx3xNZ9d0v0GoZYeHAfi8dcFWhh'}}}
} 
};


@NgModule({
    declarations:[],
    imports: [
        CommonModule,
        SocketIoModule.forRoot(config)
    ],
    providers: [
        SocketIoModule
    ]
})

export class SocketIo {

}